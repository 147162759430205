import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { appUrl } from "../helpers/helpers"

const PressRelease = () => (
  <Layout>
    <SEO title="Sostena Press Release" />
    <div className="container">
      <br/><br/>
      <h5 style={{ fontWeight: 'bold' }}>Press Release: Sostena and FarmRaise strategically align to help U.S. Family Farmers and Ranchers gain easier access to USDA financial assistance programs!</h5>

      <p>To learn more about it, simply setup your free Produceplan™ account here: <a href="https://app.produceplan.com/signup" target="_blank">app.produceplan.com/signup</a>. Once you create your account, click on “USDA Programs” under Support Section and follow the FarmRaise Link.</p>

      <p>FarmRaise is dedicated to scaling regenerative agriculture through financial access. It provides farmers with end-to-end farm funding software that sits on a database of 1k plus funding programs. FarmRaise:</p>
      
      <ul style={{ paddingLeft: 36 }}>
        <li style={{ listStyle: 'disc' }}>Matches farmers with funding opportunities,</li>
        <li style={{ listStyle: 'disc' }}>Helps farmers apply to those opportunities quickly and efficiently,</li>
        <li style={{ listStyle: 'disc' }}>Keeps farmers updated with customized-to-the-farm alerts so they never miss a funding opportunity.</li>
      </ul>

      <p>Farmers can use FarmRaise’s online EQIP application to replace 8 cumbersome government forms with a 15-minute online process.</p>

      <p>Sostena is dedicated to scaling regenerative agriculture through our cloud-based, supply-chain technology platform, ProducePlan®. We believe the future of regenerative agriculture is directly tied to the integration of IoT, AI and Autonomous Agriculture.</p>

      <p>Currently Sostena is focusing on three pillars of sustainability within the Produceplan™ eco-system:</p>

      <ol class="usda-list">
        <li><strong>Agricultural Supply-Chain Planning:</strong> Improving supply and demand equilibrium (we must stop overproducing agricultural products to help 
      promote conservation)</li>
          <div class="extra-indent">a. Remote supply chain management, remote irrigation, eco-system for new remote technologies under one platform.</div>
        <li><strong>Water Conservation:</strong> Reduce water waste utilizing IoT connected GroundSight Irrigation Technology integrated into the supply chain via Produceplan™.</li>
        <li><strong>Carbon Reduction:</strong> Promote carbon reduction of the entire cycle from the seed to the table.</li>
      </ol>


      <p>By strategically aligning with FarmRaise, Sostena will be able to help its customers, directly through their Produceplan™ accounts, gain better access and availability of financing through various USDA programs.</p>

      {/* <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Get started with USDA Financing here: </h5>
      <br/>
      <div style={{ width: '100%', textAlign: 'center' }}>
          <a
            className="btn white btn-rounded"
            href={`https://app.farmraise.com/eligibility/quiz?partner=sostena`}
            style={{ 
              color: '#000', 
              padding: '3px 40px',
              height: 48,
              fontSize: 18,
              fontWeight: 'bold',
              border: '3px solid',
            }}
          >
            Start
          </a>
      </div> */}

      <br/>

      <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Create a Produceplan™ account here:</h5>
      <br/>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <div className="home-b-header-menu-item login-button" style={{ display: 'inline' }}>
          <a
            className="btn orange btn-shadowless btn-rounded"
            href={`${appUrl}/login`}
          >
            Log in
          </a>
        </div>
        <div className="home-b-header-menu-item signup-button" style={{ display: 'inline', marginLeft: 20 }}>
          <a
            className="btn btn-shadowless btn-rounded"
            href={`${appUrl}/signup`}
          >
            Sign up
          </a>
        </div>
      </div>

      <br/><br/><br/>
    </div>
  </Layout>
)

export default PressRelease
